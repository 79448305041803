@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.ylzcc-activity-modal {
    .ant-modal-content {
        padding: 24px;
        background: #ffffff;
    }

    .ant-checkbox .ant-checkbox-inner {
        border-radius: 2px;
    }

    &-head {
        width: 100%;
        position: relative;

        span {
            height: 18px;
            font-size: 18px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 600;
            text-align: LEFT;
            color: #333333;
            line-height: 18px;
        }

        &-close {
            position: absolute;
            width: 24px;
            height: 24px;
            top: calc(50% - 24px);
            right: -14px;
            border-radius: 6px;
            cursor: pointer;

            &:hover {
                background-color: #e7e7e7;
            }
        }
    }

    &-mark {
        margin-top: 24px;

        &-choice {
            display: flex;
            justify-content: left;
            align-items: center;
            cursor: pointer;

            &-ti {
                font-size: 14px;
                font-weight: 400;
                text-align: LEFT;
                color: #333333;
                line-height: 14px;
            }

            &-left {
                transform: translateX(-4px);
                fill: #999999 !important;

                &:hover {
                    fill: #1677ff !important;
                }
            }
        }
    }

    &-score {
        width: 100%;
        border-radius: 2px;
        box-shadow: 0px 2px 8px 0px rgba(97, 92, 153, 0.15);
        padding: 20px 0 24px 20px;
        display: flex;
        align-items: center;
        margin-top: 10px;

        .title {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            text-align: LEFT;
            color: #333333;
            line-height: 20px;
        }

        .number {
            font-size: 20px;
            font-weight: 400;
            text-align: LEFT;
            line-height: 20px;
            margin-left: 10px;
            margin-right: 4px;
            position: relative;
            .input {
                width: 64px;
                height: 24px;
                background: #ffffff;
                border: 1px solid #e5e5e5;
                border-radius: 2px;
                box-shadow: 0px 1px 4px 0px rgba(97, 92, 153, 0.15);
                outline: none;
                color: #FAAD14;
                padding-left: 19px;
                padding-right: 19px;
                font-size: 14px;
                font-weight: 400;
                text-align: CENTER;
                line-height: 14px;
            }

            &-tip {
                position: absolute;
                right: -18px;
                top: calc(100% + 2px);
                display: flex;
                align-items: center;
                .name {
                    width: 132px;
                    height: 12px;
                    font-size: 12px;
                    font-weight: 400;
                    text-align: LEFT;
                    color: #f5222d;
                    line-height: 12px;
                    margin: 0px 0px 0px 3px;
                }
            }
        }

        .icon {
            width: 14px;
            height: 14px;
            cursor: pointer;
            color: #999999;

            &:hover {
                color: #1677ff;
            }
        }

        .icon-help {
            &:hover {
                color: #333333;
            }
        }

        &-one {
            display: flex;
            justify-content: left;
            align-items: center;

            span:nth-child(2) {
                color: #30c213;
            }
        }

        &-two {
            display: flex;
            justify-content: left;
            align-items: center;
            margin-left: 78px;

            span:nth-child(2) {
                color: #FAAD14;
            }
        }

        &-three {
            display: flex;
            align-items: center;
            margin-left: 80px;
            span {
                margin-left: 4px;
            }
        }

        .ant-checkbox-wrapper {
            // &:nth-child(2) {
            //     margin-left: 70px;
            // }
            flex-direction: row-reverse;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    &-tablee {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 24px;

        td, th {
            border-left: 0 !important;
        }

        .tab-activity {
            max-width: 240px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 400;
            text-align: LEFT;
        }

        .tab-score {
            max-width: 100px;
            position: relative;

            input {
                width: 64px;
                height: 24px;
                padding: 0 19px;
                background: transparent;
                outline: none;
                border: 1px solid #e5e5e5;
                border-radius: 2px;
                font-size: 14px;
                font-weight: 400;
                text-align: CENTER;
                color: #333333;
                line-height: 24px;
            }
            

            &-svg {
                width: 16px;
                height: 16px;
                margin-left: 30px;
                cursor: pointer;
                position: absolute;
                right: -8px;
                color: #999999;

                &:hover {
                    color: #1677ff;
                }
            }
        }

        .tab-editor {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            span {
                font-size: 14px;
                cursor: pointer;
                font-weight: 400;
                text-align: LEFT;
                color: #1677ff;
                line-height: 14px;
            }
        }

        .ant-table-wrapper .ant-table-thead>tr>th {
            border-bottom: 1px solid #EBEBF0 !important;
            background: #f0f1f5 !important;
            border-right: 1px solid #d9d9d9 !important;
            border-radius: 0 !important;
            color: #333333 !important;
            font-size: 16px !important;
            font-weight: 400 !important;

            &:last-child {
                border-right: none !important;
            }
        }

        .ant-table-wrapper .ant-table-tbody>tr>td {
            font-size: 14px !important;
            font-weight: 400 !important;
            color: #333333 !important;
            border-right: 1px solid #d9d9d9 !important;
            padding: 16px !important;

            &:last-child {
                border-right: none !important;
            }

        }

        .ant-table-container {
            border-radius: 0px !important;
            border: none !important;
            border-inline-start: none !important;
            border-inline-end: none !important;
        }
    }

    &-page {
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .ant-pagination .ant-pagination-item {
            border-radius: 2px;
        }
        .ant-pagination .ant-pagination-prev button, .ant-pagination .ant-pagination-next button {
            color: #999999;
            &:hover {
                color: #1677ff;
            }
        }

        .ant-pagination .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
            color: #cccccc;
        }

        .ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active):hover {
            background-color: transparent;
        }
        .ant-pagination.ant-pagination-mini .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination.ant-pagination-mini .ant-pagination-next:hover .ant-pagination-item-link {
            background-color: transparent;
        }

        .btn {
            cursor: pointer;
            width: 64px;
            height: 32px;
            background: #1677ff;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 14px;
            outline: none;
            border: none;

            &:hover {
                background: #4592ff;
            }
        }
    }

    .ant-pagination {

        &-item {
            // border: none;
            border-color: #d9d9d9; 

            &:hover {
                border-color: #d9d9d9; 
                background-color: #f2f2f2 !important;
            }
            &-active {
                a {
                    color: rgba(0, 0, 0, 0.88) !important;
                    font-weight: 400 !important;
                }
                &:hover {
                    a {
                        color: rgba(0, 0, 0, 0.88);
                    }
                }
            }
        }

    }
}

.ylzcc-popover-help {
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    box-shadow: 2px 2px 4px 0px rgba(92, 117, 153, 0.10);

    .ant-popover-inner {
        padding: 6px 4px;
        border-radius: 0px;
    }

    &-tit {
        font-size: 12px;
        font-weight: 400;
        text-align: LEFT;
        color: #999999;
        line-height: 12px;
    }
}