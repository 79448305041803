@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.ylzcc-modal {

    .ant-modal .ant-modal-close {
        color: #999999;

        &:hover {
            background-color: transparent;
            color: #1677ff;
        }
    }


    .form-item-check {
        >div {
            margin-bottom: 16px;
            display: flex;
            align-items: center;
        }

        &-tip {
            margin-left: 10px;
            display: flex;
            align-items: center;

            >span {
                margin-left: 2px;
                font-size: 12px;
                color: #999999;
            }
        }

        .ant-checkbox-wrapper {
            &:first-child {
                margin-bottom: 0;
            }

            width: fit-content;
            display: flex;
            flex-direction: row-reverse;
        }

        .ant-checkbox+span {
            margin-right: 4px;
            min-width: 70px;
            padding-inline-start: 10px;
            padding-inline-end: 10px;
        }

        .ant-checkbox .ant-checkbox-inner {
            border-radius: 2px;
        }

        .form-item-check-checkBox {
            display: flex;
            align-items: flex-start;

            .checkBox-text {
                display: flex;
                align-items: center;
                margin-top: 1px;
                margin-left: 10px
            }
        }
    }

    .ant-modal-content {
        padding: 30px 50px 14px;
        max-height: calc(100vh - 80px);
        background: #ffffff;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .ant-modal-footer {
            display: none;
        }

        .ant-form-item .ant-form-item-label>label {
            position: initial;
        }

        .ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            display: inline-block;
        }

        .ant-form-item-no-colon {
            font-size: 14px !important;
            font-weight: 400 !important;
        }


        .ant-input:focus,
        .ant-input-affix-wrapper-focused {
            // border-color: #4096ff;
            box-shadow: 0 0 0px 1px #4096ff;
        }

        .ant-form-item {
            margin-bottom: 20px;

            &-explain-error {
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
                margin-top: 1px;
            }

            // .ant-input-textarea-affix-wrapper {
            //     position: relative;
            //     .ant-input-suffix {
            //         position: absolute;
            //         right: 14px;
            //         top: 0;
            //         width: 0px;
            //         height: 100%;
            //     }
            // }

            textarea {
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }


        .form-item-choise {
            margin-left: 81px;
            border: 1px solid #e5e5e5;
            border-radius: 2px;

            &-title {
                width: 100%;
                height: 32px;
                background: #f5f6fa;
                border-bottom: 1px solid #e5e5e5;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: LEFT;
                    color: #333333;
                    line-height: 16px;

                    &:nth-child(1) {
                        margin-left: 124px;
                    }

                    &:nth-child(2) {
                        margin-right: 34px;
                    }
                }
            }

            &-select {
                width: 100%;
                padding: 12px 10px 12px 8px;
                display: flex;
                flex-direction: column;
                gap: 12px;

                &-item {
                    width: 100%;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    height: 24px;

                    &-input {
                        flex: 1;
                        position: relative;

                        &-add {
                            height: 24px;
                            border: 1px dashed #d9d9d9;
                            border-radius: 2px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            color: #666666;

                            span {
                                font-size: 13px;
                                font-weight: 400;
                                text-align: LEFT;
                                line-height: 15px;
                            }

                            &:hover {
                                border: 1px dashed #1677ff;
                                color: #1677ff;
                            }
                        }

                        .tip {
                            position: absolute;
                            height: 24px;
                            left: 0px;
                            top: 0;
                            font-size: 13px;
                            font-weight: 400;
                            text-align: LEFT;
                            color: #f5222d;
                            line-height: 24px;
                            padding-left: 8px;
                            z-index: 90;
                        }
                    }

                    &-cho {
                        margin-left: 20px;
                        width: 24px;
                        height: 24px;

                        .que {
                            width: 24px;
                            height: 24px;
                            border: 1px solid #d9d9d9;
                            border-radius: 2px;
                            cursor: pointer;

                            &:hover {
                                border: 1px solid #30c213;
                            }
                        }

                        .icon {
                            width: 24px;
                            height: 24px;
                            cursor: pointer;
                        }
                    }

                    &-del {
                        margin-left: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .form-item-score {

            .ant-input:focus,
            .ant-input-affix-wrapper:focus {
                border-color: #4096ff !important;
            }

            .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
                border-color: #ff4d4f !important;
            }
        }


        .form-item-check-small {
            margin-bottom: 0px;
            height: 20px;

            .ant-checkbox-wrapper {
                &:nth-child(2) {
                    margin-left: 60px;
                }

                flex-direction: row-reverse;
            }
        }

        .form-item-tip {
            display: flex;
            margin-left: 6px;

            &-btn {
                height: 20px;
                display: flex;
                justify-content: left;
                align-items: center;
                cursor: pointer;
                position: relative;

                span {
                    margin-left: 4px;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: LEFT;
                    color: #1677ff;
                    line-height: 16px;
                    margin-right: 4px;
                }
            }
        }

        .form-item-tiptab {
            width: 100%;
        }

        .form-item-button {
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                cursor: pointer;
            }

            gap: 40px;

            &-can {
                width: 88px;
                height: 32px;
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                outline: none;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                color: #333333;
                line-height: 14px;

                &:hover {
                    border: 1px solid #1677ff;
                    color: #1677ff;
                }
            }

            &-en {
                outline: none;
                width: 88px;
                height: 32px;
                background: #1677ff;
                border-radius: 2px;
                border: none;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                color: #ffffff;
                line-height: 14px;

                &:hover {
                    background: #4592ff;
                }
            }
        }

        .ant-picker {
            width: 180px;
            border-radius: 2px;
        }


        .form-item-help {
            position: relative;

            &-icon {
                cursor: pointer;
                position: absolute;
                right: 0;
                width: 16px;
                height: 16px;
                top: calc(100% + 7px);
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-text {
                cursor: pointer;
                position: absolute;
                right: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                text-decoration: underline;
                color: #1677ff;
            }

            &-btn {
                cursor: pointer;
                position: absolute;
                right: 10px;
                top: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                text-decoration: underline;
                color: #1677ff;

                &:nth-child(2) {
                    top: 60px;
                }
            }
        }
    }

}




.ylzcc-form-head {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color: #333333;
        line-height: 18px;
    }
}


.ylzcc-ability {
    width: 100%;
    border-radius: 2px;
    box-shadow: 0px 2px 8px 0px rgba(97, 92, 153, 0.15);
    padding: 16px 20px 16px 12px;
}

.ylzcc-activity-header {
    width: 100%;
    height: 16px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    justify-content: space-between;
    margin-bottom: 20px;

    span {
        &:nth-child(1) {
            height: 16px;
            font-size: 16px;
            font-weight: 600;
            text-align: LEFT;
            color: #333333;
            line-height: 16px;
        }

        &:nth-child(2) {
            height: 14px;
            font-size: 14px;
            font-weight: 400;
            text-align: LEFT;
            color: #1677ff;
            line-height: 14px;
            cursor: pointer;
        }
    }
}




// 提示
.tipability {
    padding-bottom: 1px;
}

