@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.tools {
    padding: 10px 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px 30px;


    &-tool {
        @size: 40px;
        width: @size;
        height: 60px;
        font-weight: 500;
        text-align: CENTER;
        color: #cccccc;
        cursor: pointer;
        position: relative;

        &-icon {
            width: @size;
            height: @size;
            background: #2f2f2f;
            border-radius: 4px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            color: #1677ff;

            .tools-tool-icon {
                background: #404040;
            }

            .tools-tool-del {
                display: block;
            }

            // .tools-tool-custom {
            //     border: 1px solid #1677ff;
            //     border-top: none;
            // }
        }

        &-name {
            margin-top: 8px;
            font-size: 12px;
            height: 12px;
            width: 100%;
            position: relative;

            span {
                max-width: calc(100% + 30px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
            }
        }

        &-del {
            position: absolute;
            display: none;
            width: 14px;
            height: 14px;
            right: -4px;
            top: -7px;
            cursor: pointer;

            svg {
                .path1 {
                    fill: #9D9D9D;
                }

                .path2 {
                    fill: #2F2F2F;
                }
            }

            &:hover {
                svg {
                    .path1 {
                        fill: white;
                    }

                    .path2 {
                        fill: #333333;
                    }
                }
            }
        }

        &-custom {
            position: absolute;
            top: 26px;
            left: 0;
            width: 100%;
            height: 14px;
            font-size: 10px;
            font-weight: 400;
            text-align: CENTER;
            color: #ffffff;
            line-height: 14px;
            opacity: 0.5;
            background: rgba(22,119,255,.2);
            border-radius: 0px 0px 4px 4px;
        }

    }

    .tool-disable {
        opacity: 0.5;
        color: #cccccc;

        .tools-tool-icon {
            background: #2f2f2f;
        }

        .tools-tool-del {
            display: none !important;
        }
    }
}

.tools {
    &-menus {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 8px;

        &-right {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: right;
            gap: 10px;

            &-icon {
                cursor: pointer;
            }
        }
    }

    &-loading {
        display: flex;
        align-items: center;
        justify-content: left;
        flex-wrap: nowrap;

        &-rotate {
            animation: rotate 2s linear infinite;
        }

        @keyframes rotate {
            0% {
                transform: rotate(0);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        span {
            font-size: 14px;
            font-weight: 400;
            text-align: LEFT;
            color: #9d9d9d;
            line-height: 14px;
            padding-left: 4px;
        }
    }
}