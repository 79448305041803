@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
// 导航栏高度
@nav-height: 34px;

.container {
    width: 100%;
    height: 100%;


    &-nav {
        height: @nav-height;
        width: 100%;
        background-color: @container-nav;
        display: flex;
        justify-content: left;
        align-items: center;

        .between {
            height: 100%;
        }
    }


    &-content {
        height: calc(100% - @nav-height);
        width: 100%;
        position: relative;
    }
}

.container-nav-tabs {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 0;
        border-radius: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #444444;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #666666;
    }

    &:hover {
        &::-webkit-scrollbar {
            height: 3px;
        }
    }
}

.between {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-right: 10px;
}


.container-nav-other {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 10px;

    .ant-btn {
        padding: 0 10px;
        min-width: 56px;
        height: 24px;
        border-radius: 2px;
        font-size: 12px;
    }
}

div,
span,
ul,
li {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}