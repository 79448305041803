@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.cloud-env-main {
  position: absolute;
  padding-top: 8px;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 322px;
  z-index: 1000;

  .cloud-env {
    padding: 20px;
    max-height: calc(100vh - 66px);
    background: @env-container;
    border: 0.5px solid @background-border;
    border-radius: 10px;
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.25);
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-name {
        font-size: 16px;
        font-weight: 600;
        color: @header-name;
      }

      &-act {
        display: flex;
        align-items: center;

        >span {
          cursor: pointer;
          color: @header-name;
          display: flex;
          align-items: center;
          line-height: 16px;

          &:hover {
            color: #1677ff;

            >span {
              color: #1677ff;
            }
          }

          &:nth-child(1) {
            padding-right: 10px;
          }

          &:nth-child(2) {
            padding-left: 10px;
            border-left: 1px solid #d9d9d9;
          }

          >span {
            margin-left: 4px;
            color: @header-name;
            font-size: 14px;
          }
        }
      }
    }

    &-ul {

      &-li {
        margin-top: 20px;

        &-name {
          margin-bottom: 10px;
          width: 120px;
          font-size: 14px;
          font-weight: 600;
          color: @header-name;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }

        &-info {
          display: flex;
          justify-content: space-between;

          >div {
            flex: 1;
            padding: 10px;
            background: @background-light;
            border-radius: 4px;
            color: @env-ccc;
            &:nth-child(1) {
              margin-right: 10px;
            }

            div {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 13px;

              &:nth-child(2) {
                margin-top: 8px;
                font-size: 12px;
                color: @foreground-dimmest;

                >span {
                  &:first-child {
                    width: 70px;
                    height: 4px;
                    border-radius: 8px;
                    background-color: @background-root;
                    overflow: hidden;

                    span {
                      display: block;
                      width: 0%;
                      height: 4px;
                      border-radius: 8px;
                      background-color: @foreground-dimmer;
                      transition: all 0.1s;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .cloud-env-loading {
    width: 322px;
    height: 250px;
    background: @env-container;
    border: 0.5px solid @background-border;
    border-radius: 10px;
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.25);

    .loading {
      background: transparent;
      height: 100%;

      .loading-line {
        scale: 0.46;
        top: calc(50% - 100px);
      }

      p {
        margin-top: 135px;
        width: 208px;
        font-size: 12px;
        text-align: center;
        color: #999999;
        line-height: 20px;
      }
    }
  }
}



.cloud-env-tooltip {
  .ant-tooltip-inner {
    padding: 4px 8px;
    color: #666666;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    box-shadow: 2px 2px 4px 0px rgba(92, 117, 153, 0.10);
  }
}