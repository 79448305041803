@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.download-setting {
  .ant-modal .ant-modal-content {
    padding: 30px 50px;
    max-height: calc(100vh - 80px);
  }

  .ant-modal-footer {
    margin-top: 0;
  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    color: @icon-default;

    &:hover {
      cursor: pointer;
      color: @accent-primary-default;
    }
  }

  &-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #333333;
    line-height: 18px;
  }

  &-list {

    &-th {
      margin-bottom: 8px;
      border-bottom: 0.5px solid #d9d9d9;
      display: flex;
      align-items: center;

      >div {
        padding: 10px 0;
        font-size: 16px;
        color: #666666;
        line-height: 16px;

        &:nth-child(1) {
          flex: 1;
        }

        &:nth-child(2) {
          width: 80px;
          text-align: center;
        }

        &:nth-child(3) {
          margin-left: 40px;
          width: 80px;
          color: #cccccc;
          text-align: right;
        }
      }
    }

    &-trs {
      max-height: calc(100vh - 220px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #bbbbbb;
      }

      >div {
        display: flex;
        align-items: center;

        >div {
          padding: 10px 0;

          &:nth-child(1) {
            flex: 1;
          }

          &:nth-child(2) {
            width: 80px;
            text-align: center;
          }

          &:nth-child(3) {
            margin-left: 40px;
            width: 80px;
            text-align: center;
          }
        }
      }
    }

    &-tr {
      .file-name {
        display: flex;
        align-items: center;
        overflow: hidden;

        .name {
          flex: 1;
          margin-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: nowrap;
          cursor: pointer;
        }
      }

    }

    &-empty {
      margin-top: 22px;
      justify-content: center;
      color: #666666;
    }
  }
}