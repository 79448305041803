@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.layout {
  width: 100vw;
  height: 100vh;
  background-color: @background-root;
  min-width: 1200px;
  overflow-x: auto;

  .heardText {
    line-height: 24px !important;
  }

  .readerTextItem>div>table {
    margin-bottom: 10px !important;
  }

  .readerTextItem {

    th,
    td {
      min-width: 100px;
      border: 0.5px solid #e5e5e5 !important;
      /* 设置单元格边框 */
      padding: 8px 20px !important;
      /* 设置单元格内边距 */
      /* text-align: center !important; 设置单元格文本居中对齐 */
    }

  }

  &-container {
    height: calc(100vh - @header-height - @footer-height);
    display: flex;
    background: @background-container;

    .loading {
      position: relative;
      // background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        margin-top: 10px;
        color: transparent;
        font-size: 14px;
        line-height: 14px;
      }

      &-text {
        position: absolute;
        width: 285px;
        top: 100px;
        text-align: center;
        font-size: 14px;
        color: #ccc;
      }

      &-line {
        position: absolute;
        left: calc(50% - 70px);
        top: calc(50% - 70px);
        width: 140px;
        height: 140px;
        z-index: 1;

        div {
          position: absolute;
          width: 10px;
          height: 35px;
          border-radius: 6px;
          background: #5976eb;
          animation: load 1.04s ease infinite;
          -webkit-animation: load 1.04s ease infinite;

          &:nth-child(1) {
            left: 17px;
            top: 50%;
            margin-top: -15px;
            transform: rotate(270deg);
            animation-delay: 0.13s;
            -webkit-animation-delay: 0.13s;
            opacity: 1;
          }

          &:nth-child(2) {
            left: 30px;
            top: 20px;
            transform: rotate(315deg);
            animation-delay: 0.26s;
            -webkit-animation-delay: 0.26s;
            opacity: 0.9;
          }

          &:nth-child(3) {
            left: 50%;
            top: 5px;
            margin-left: -6px;
            transform: rotate(0);
            animation-delay: 0.39s;
            -webkit-animation-delay: 0.39s;
            opacity: 0.8;
          }

          &:nth-child(4) {
            top: 20px;
            right: 30px;
            transform: rotate(45deg);
            animation-delay: 0.52s;
            -webkit-animation-delay: 0.52s;
            opacity: 0.7;
          }

          &:nth-child(5) {
            right: 17px;
            top: 50%;
            margin-top: -15px;
            transform: rotate(90deg);
            animation-delay: 0.65s;
            -webkit-animation-delay: 0.65s;
            opacity: 0.6;
          }

          &:nth-child(6) {
            right: 30px;
            bottom: 20px;
            transform: rotate(135deg);
            animation-delay: 0.78s;
            -webkit-animation-delay: 0.78s;
            opacity: 0.5;
          }

          &:nth-child(7) {
            bottom: 5px;
            left: 50%;
            margin-left: -6px;
            transform: rotate(180deg);
            animation-delay: 0.91s;
            -webkit-animation-delay: 0.91s;
            opacity: 0.4;
          }

          &:nth-child(8) {
            bottom: 20px;
            left: 30px;
            transform: rotate(225deg);
            animation-delay: 1.04s;
            -webkit-animation-delay: 1.04s;
            opacity: 0.3;
          }
        }

        @keyframes load {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 0.2;
          }
        }

        @-webkit-keyframes load {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 0.2;
          }
        }
      }
    }

    &-sidebar {
      position: relative;
      min-width: 220px;
      overflow-y: auto;
      background-color: @background-root;

      .header {
        font-weight: 500;
      }

      .tree-node {
        color: #666;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 8px;
        // padding: 8px 8px 8px 18px !important;
      }

      .tree1 {
        padding: 8px 8px 8px 26px !important;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .header {
        padding: 16px 8px 8px 10px !important;
      }

      .tree2 {
        padding: 8px 8px 8px 34px !important;
      }

      .title {
        white-space: nowrap;
        /* 不换行 */
        overflow: hidden;
        /* 超出部分隐藏 */
        text-overflow: ellipsis;
      }
    }

    &-foldsidebar {
      width: @sider-fold-width;
      background-color: @background-container;
      border-right: 1px solid @background-border;
    }

    &-empty {
      flex: 1;
      background: @background-container;
      // border: 0.5px solid @outline-stronger;
      // border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateY(-40px);

        .span {
          margin-top: 20px;
          font-size: 14px;
          color: @foreground-dimmest;
        }

      }
    }

    &-content {
      flex: 1;
      width: 0px;
      display: flex;

      .markdown {
        max-width: 100% !important;

        .readerTextContain {
          width: 100%;
          max-width: 1300px !important;
          padding: 10px 30px !important;

          blockquote {
            width: 100% !important;
            box-sizing: border-box;
          }

          .execSpan {
            .hljs {
              box-sizing: border-box;

              &:hover {
                &::-webkit-scrollbar-thumb {
                  background: #444;
                  border-radius: 2px;
                }
              }

              &::-webkit-scrollbar {
                height: 6px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: transparent;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                border-radius: 2px;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #666;
              }
            }
          }
        }
      }

      .pagechange {
        margin-top: 10px;
      }


      ul {
        margin: 7px 0px 7px 20px;

      }

      ol {
        // margin-left: 0 !important;
        padding-inline-start: 0px;
        margin: 0px 0px 0px 20px;

        li {
          margin: 7px 0px 7px 0px;
        }
      }

      .react-pdf__Page__canvas {
        max-width: 100% !important;
      }

      &-container {
        width: 50%;
        min-width: 250px;
        position: relative;
        background: @background-default;
        overflow: hidden;

        &:first-child {
          flex: 1;
        }

        &-topright {
          position: absolute;
          top: 4px;
          right: 8px;

          .ant-btn {
            padding: 0 10px;
            min-width: 56px;
            height: 24px;
            border-radius: 2px;
            font-size: 12px;
          }

          .ant-green {
            margin-left: 10px;
            background: #30c213;

            &:hover {
              background: #30c213 !important;
            }
          }
        }

        .runbtn {
          padding: 0;
          width: 56px;
          height: 24px;
          border-radius: 2px;
          font-size: 12px;
        }

        .xterm {
          height: 100%;
        }

        .xterm .xterm-viewport {
          overflow-y: auto;
        }
      }
    }

    &-rightsidebar {
      width: @sider-fold-width;
      background-color: @background-container;
      border-left: 1px solid @background-border;
    }

  }

  &-footer {
    height: @footer-height;
    background-color: @background-root;
    border-top: 0.5px solid #333333;
  }

  .noInfo {
    position: absolute;
    top: 50px;
    width: 100%;
    padding: 0 30px;
    // display: flex;
    // justify-content: center;
    font-size: 13px;
    color: #999999;
  }
}


.warn2 .ant-modal-content {
  margin-inline-start: 0px !important;
  margin-block-start: 0px !important;

  .ant-modal-confirm-content {
    // margin-inline-start: 38px !important
  }

}

.sidebar-split {
  flex: 1;
  margin-top: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid transparent;

}

.sidebar-bottom {
  display: flex;
  flex: 1;
  height: 24px;
  background: #fff9f3;
  border-radius: 4px;
  margin-bottom: 5px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #888888;
  padding-left: 10px;
  margin-top: 8px;
}

.sidebar-split1 {
  flex: 1;
  margin-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
}


.component .header .left .title {
  font-size: 15px;
}

.tree-node {
  font-size: 13px;
}

.tree-node .title {
  flex: 1;
}

.xterm-screen {
  overflow: hidden;
  padding-left: 5px;
}

.freshh {
  width: 14px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  transform: translateY(1px);

  &-rotate {
    animation: rotatee 1s linear infinite;
  }

  @keyframes rotatee {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}