@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
@node-height: 28px;       // 节点高度
@node-hover: #37373d;   // 节点高亮颜色
@node-font-color: #cccccc;//字体颜色
@node-font-hightlight: #ffffff; //字体高亮的颜色

.tree-node {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: pointer;
    padding: 8px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    height: @node-height;
    border-radius: 4px;
    
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .rotate-element {
        animation: rotate 3s infinite linear;
    }

    .icon {
        width: fit-content;
        height: auto;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    // .icon.right {
    //     display: none;
    // }

    .icon.show {
        display: block;
    }


    img {
        max-width: 16px;
    }

    .title {
        flex: 1;
        margin-left: 8px;
        box-sizing: border-box;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        color: @node-font-color;
    }

    input {
        flex: 1;
        outline: none;
    }

    .menu {
        width: 0;
        height: 0;
        position: relative;
        top: 30px;
        display: none;

        .body {
            display: flex;
            position: absolute;
            top: -10px;
            right: 5px;
            min-width: 45px;
            background-color: #fff;
            box-sizing: border-box;
            border-radius: 4px;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
    }

    .show {
        display: block;
    }

    svg {
        fill: #CCCCCC !important;
    }
}

.tree-drag {
    border: 1px solid #1677ff;
}

.tree-hover-after {
    position: relative;
    background-color: @node-hover;

    &::before {
        position: absolute;
        width: 100%;
        content: '';
        bottom: 0;
        left: 0;
        height: 1.5px;
        background-color: #1677ff;
    }
}

.tree-hover-before {
    position: relative;
    background-color: @node-hover;
    
    &::before {
        position: absolute;
        width: 100%;
        content: '';
        top: 0;
        left: 0;
        height: 1.5px;
        background-color: #1677ff;
    }
}

.tree-node:hover {
    background-color: @node-hover;
}
.tree-node-input {
    background-color: transparent;
    border: none;
    color: @node-font-color;
}

.tree-node:hover .icon.right {
    display: block;
    min-width: 14px;
    height: 14px;
    display: flex;
    align-items: center;

    svg {
        fill: #9d9d9d !important;
    }
}

.tree-node .icon.right:hover {
    // border-radius: 4px;
    background-color: #35353a;
    svg {
        fill: white !important;
    }
}

.selected {
    background-color: @node-hover;

    .title {
        color: @node-font-hightlight;
    }

    svg {
        fill: white !important;
    }
}

.tree0 {
    padding-left: 10px;
}

.tree1 {
    padding-left: 26px;
}

.tree2 {
    padding-left: 42px;
}

.tree3 {
    padding-left: 58px;
}

.tree4 {
    padding-left: 74px;
}


.tree5 {
    padding-left: 90px;
}

.tree-node input {
    outline: none;
}