@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.choose-modal {
  overflow: hidden !important;

  .ant-modal {
    max-width: 100vw;
    top: 60px;
    padding-bottom: 0;
    overflow: hidden;

    .ant-modal-content {
      padding: 30px;
      height: calc(100vh - 60px);
      border-radius: 30px 30px 0px 0px !important;
      overflow: hidden;

      .ant-modal-body {
        margin: auto;
        max-width: 1200px;
        overflow: hidden;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .ant-modal-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        margin-top: 0;
        padding-bottom: 20px;
        text-align: center;
        background-color: #ffffff;

        .ant-btn {
          padding: 4px 30px;
        }

        .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
          margin-inline-start: 30px;
        }
      }
    }
  }

  &-content {
    height: calc(100% - 60px);
    min-height: 330px;

    &-title {
      margin-bottom: 30px;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      line-height: 18px;
      text-align: center;
    }

    &-table {
      height: calc(100vh - 220px);

      .ant-table-body {
        overflow-y: auto !important;

        &::-webkit-scrollbar {
          width: 0;
          height: 8px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ededed;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: #e0e0e0;
          cursor: pointer;
        }
      }

      .choicemodal-content {
        .content {
          max-width: 560px !important;
        }
      }

      .ant-table-tbody tr {
        .order {
            height: 1px;

            .centerstart {
                height: 100%;
                line-height: 22px !important;
                width: 100%;
            }
        }
    }

      .ant-table-thead>tr>th,
      .ant-table-thead>tr>td {
        background-color: #f5f6fa;
        font-size: 16px;
        font-weight: 400;
        text-align: CENTER;
        color: #666666;
        line-height: 16px;
        border-radius: 0 !important;

        &::before {
          display: none;
        }
      }

      .ant-table-cell {
        font-size: 14px;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
        line-height: 14px;
      }

      .ant-table-thead,
      .ant-table-row {
        height: 40px;
      }

      .ant-table-header {
        border-radius: 0px !important;
      }

      .ant-table-header .ant-table-cell-scrollbar {
        width: 0 !important;
      }

      .ant-checkbox .ant-checkbox-inner {
        border-radius: 2px;
      }

      .ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
        background: transparent;
      }
    }

    .text-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      cursor: pointer;
      line-height: normal;
    }
  }
}