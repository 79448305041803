@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.component {
    display: flex;
    min-width: 198px;
    width: 100%;
    font-size: 14px;
    color: #333;
    flex-direction: column;
    // justify-content: center;
    // align-items: flex-start;
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;


    .resourceBtn {
        height: 32px;
        width: 100%;
        padding: 0 8px;

        svg {
            fill: white !important;
        }

        .rightBtn {
            display: flex;
            flex-direction: row;
            height: 32px;
            align-items: center;
            justify-content: flex-end;
            border-bottom: 1px solid #333333;

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-right: 10px;
                width: 20px;
                height: 20px;

                &:hover {

                    background: #404047;
                    border-radius: 2px;
                }
            }

            .icon:last-child {
                margin-right: 0px;
            }

            img {
                width: 16px !important;
                padding: 4px;
                margin-right: 4px !important;
            }

            img:last-child {
                margin-right: 0px !important;
            }

            .animation {
                animation: rotate 0.5s;
            }

            @keyframes rotate {
                0% {
                    transform: rotate(90deg);
                }

                50% {
                    transform: rotate(180deg);
                }

                75% {
                    transform: rotate(270deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }

    //     .header {
    //         display: flex;
    //         width: 100%;
    //         flex-direction: row;
    //         justify-content: space-between;
    //         padding: 8px;
    //         box-sizing: border-box;
    //         font-size: 14px;
    //         color: #333;
    //         min-height: 32px;

    //         .left {
    //             position: relative;
    //             display: flex;
    //             cursor: pointer;
    //             align-items: center;
    //             justify-content: space-between;
    //             max-width: 200px;
    //             flex: 1;
    //             margin-right: 70px;
    //             .icon {
    //                 width: fit-content;
    //                 height: auto;
    //             }

    //             img {
    //                 width: 16px !important;
    //             }

    //             .title {
    //                 box-sizing: border-box;
    //             }
    //             .bottom-line{
    //                 position: absolute;
    //                 bottom: -8px;
    //                 width: 60px;
    // height: 2px;
    // background: #1677ff;
    // transition: left 0.3s;
    //             }
    //             .bottom-line-active{
    //                 right: 0;
    //             }
    //         }

    //         .right {
    //             display: flex;
    //             flex-direction: row;
    //             align-items: center;
    //             justify-content: space-between;

    //             .icon {
    //                 height: auto;
    //                 cursor: pointer;
    //                 margin-right: 10px;
    //             }

    //             .icon:last-child {
    //                 margin-right: 0px;
    //             }

    //             img {
    //                 width: 16px !important;
    //                 padding: 4px;
    //                 margin-right: 4px !important;
    //             }

    //             img:last-child {
    //                 margin-right: 0px !important;
    //             }

    //             .animation {
    //                 animation: rotate 0.5s;
    //             }

    //             @keyframes rotate {
    //                 0% {
    //                     transform: rotate(90deg);
    //                 }

    //                 50% {
    //                     transform: rotate(180deg);
    //                 }

    //                 75% {
    //                     transform: rotate(270deg);
    //                 }

    //                 100% {
    //                     transform: rotate(360deg);
    //                 }
    //             }
    //         }
    //     }

    .body {
        width: 100%;
        flex: 1;
        overflow-y: auto;
        margin-top: 2px;

        &-drop {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            flex-wrap: wrap;
        }

        &-none {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 18px 0 20px 20px;
            height: 100%;
            font-size: 13px;
            color: #9d9d9d;
            line-height: 20px;
        }
    }

    ::-webkit-scrollbar {
        display: none;
        /* Safari 和 Chrome */
    }

    &-header {
        width: 100%;

        svg {
            fill: @outline-stronger  !important;
        }

        &-btn {
            cursor: pointer;
            font-size: 14px;
            color: #ffffff;
            line-height: 16px;

            &:hover {
                font-weight: 600 !important;
                color: #ffffff !important;
            }
        }

        &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

}