@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.setenv-modal {
    padding-bottom: 0px !important;

    .ant-modal {
        padding-bottom: 0 !important;
    }

    .ant-modal-content {
        width: 100%;
        box-sizing: border-box;
        height: calc(100vh - 60px);
        padding: 30px 120px !important;
        position: relative;
        border-radius: 30px 30px 0px 0px !important;

    }

    .ant-modal-body {
        width: 100%;
        height: 100%;
    }

    &-close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        fill: #999999;

        &:hover {
            fill: #1677ff;
        }

    }

    &-form {
        .ant-form-item {
            margin-bottom: 20px !important;
        }

        .ant-form-item-no-colon {
            font-size: 16px !important;
            font-weight: 600 !important;
            font-size: 16px;
            color: #333333;

            &::before {
                display: none !important;
            }
        }

        .ant-form-item-label {
            padding-bottom: 20px;
        }

        .ant-form-item-explain-error {
            line-height: 20px;
        }

        .ant-input-affix-wrapper {
            border-radius: 2px !important;
            border-color: #e5e5e5;

            &-focused {
                border-color: #1677ff !important;
            }
        }

        .ant-input {
            border-radius: 2px !important;
            border-color: #e5e5e5 !important;

            &:focus {
                border-color: #1677ff !important;
            }
        }

        .ant-select-selector {
            border-radius: 2px !important;
            border-color: #e5e5e5;
        }
    }

    &-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        &-left {
            font-size: 16px !important;
            font-weight: 600 !important;
            font-size: 16px;
            color: #333333;
        }

        &-right {
            display: flex;
            align-items: center;
            justify-content: center;

            &-btn {
                width: 96px;
                height: 32px;
                cursor: pointer;
                border-radius: 2px;
                background: #f5f6fa;
                display: flex;
                align-items: center;
                justify-content: center;
                --svgColor: #666666;
                font-size: 14px;
                font-weight: 400;
                color: var(--svgColor);
                line-height: 14px;

                .svg1 {
                    stroke: var(--svgColor);
                }

                .svg2 {
                    fill: var(--svgColor);
                }

                span {
                    padding-left: 4px;
                }

                &:hover {
                    --svgColor: #fff;
                    background: #1677ff;
                }

            }
            .light {
                --svgColor: #fff;
                background: #1677ff;
            }
        }
    }

    &-tabs {
        height: calc(100% - 132px - 72px);
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        overflow: hidden;

        &-nav {
            height: 34px;
            background: #f5f6fa;
            display: flex;
            align-items: center;
            justify-content: left;

            &-item {
                height: 100%;
                max-width: 120px;
                padding: 3px 10px;
                font-size: 14px;
                font-weight: 400;
                line-height: 28px;
                color: #999999;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: relative;
                cursor: pointer;

                &:not(:nth-last-of-type(2))::after {
                    position: absolute;
                    top: 7px;
                    right: 0;
                    width: 1px;
                    height: 20px;
                    content: '';
                    background-color: #d9d9d9;
                }

                &:hover {
                    color: #333333;
                }

            }
            
            .light {
                color: #333333;
                background-color: #fff;
            }

            &-add {      
                width: 14px;
                height: 15px;
                margin-left: 10px;

                svg {
                    cursor: pointer;
                    --color: #999999;

                    circle {
                        stroke: var(--color);
                    }

                    path,
                    rect {
                        fill: var(--color);
                    }

                    &:hover {
                        --color: #1677ff;
                    }
                }

                &-dropdown {
                    background: #ffffff;
                    border-radius: 4px;
                    box-shadow: 2px 2px 4px 0px rgba(41,45,51,0.10); 

                    max-height: 300px;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                    
                    .ant-dropdown-menu {
                        border-radius: 4px;
                        padding: 0px;
                    }

                    .ant-dropdown-menu-item-active:hover {
                        background-color: #f0f2f5 !important;
                        color: #1677ff !important;
                    }

                    .ant-dropdown-menu-item {
                        color: #666666 !important;
                    }
                }
            }
        }

        &-env {
            height: calc(100% - 34px);
            position: relative;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            &-del {
                position: absolute;
                right: 20px;
                top: 20px;
                width: 20px;
                height: 21px;
                cursor: pointer;
            }
        }

        &-content {
            width: 100%;
            --height: calc(100vh - 316px);
            .CodeMirror {
                height: var(--height);
                // pointer-events: none;
                // touch-action: none;
            }
        }
    }

    &-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        margin-top: 30px;
    }
}

.tooltip-nav {
    max-width: 130px !important;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 2px !important;
    box-shadow: 2px 2px 4px 0px rgba(92, 117, 153, 0.10);
    overflow: hidden;
    
    .ant-tooltip-content {
        width: 100%;
        height: 100%;

        .ant-tooltip-inner {
            background-color: #ffffff !important;
            min-height: 0px !important;
            padding: 4px 8px;
            font-size: 12px;
            font-weight: 400;
            text-align: LEFT;
            color: #666666;
            line-height: 16px;
        }
    }
}


.CodeMirror-gutter-wrapper {
    left: -30px !important;
    // position: absolute !important;
    // top: 0 !important;
}

.ql-snow .ql-editor blockquote {
    border-left: 4px solid #e5e5e5 !important;
}

.ql-toolbar.ql-snow {
    border: 1px solid #e5e5e5 !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: #e5e5e5 !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: #e5e5e5 !important;
}

.ql-snow .ql-tooltip {
    border: 1px solid #e5e5e5;
}

.ql-snow .ql-tooltip input[type=text] {
    border: 1px solid #e5e5e5 !important;
}

.ql-snow .ql-tooltip a.ql-action::after {
    border-right: 1px solid #e5e5e5 !important;
}

.ql-container.ql-snow {
    border: 1px solid #e5e5e5 !important;
}


.cm-atom {
    color: rgb(23, 2, 163) !important;
}



/* BASICS */
.CodeMirror {
    /* Set height, width, borders, and global font properties here */
    font-family: monospace;
    // height: 300px;
    height: 200px;
    color: black;
    direction: ltr;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
}

/* PADDING */

.CodeMirror-lines {
    padding: 4px 0;
    /* Vertical padding around content */
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
    padding: 0 4px;
    // padding-left: 20px;
    /* Horizontal padding of content */
}


.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
    background-color: white;
    /* The little square between H and V scrollbars */
}

/* GUTTER */
.CodeMirror-gutters {
    border-right: 1px solid #ddd;
    background-color: #f7f7f7;
    white-space: nowrap;
}



.CodeMirror-line {
    padding-left: 6px !important;
}

// .CodeMirror-line > span {
//     padding-left: 20px;
// }

// .CodeMirror {
//     // overflow-x: hidden;
// }

.CodeMirror-gutters {
    min-width: 20px;
    left: 0px !important;
    // transform: translateX(-4px);
}

.CodeMirror-linenumber {
    // left: 0px !important;
    // position: relative;
    // &::before {
    //     position: absolute;
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     background-color: red;
    // }
}

// pre.CodeMirror-line {
//     padding-left: 30px !important;

// }




.CodeMirror-linenumbers {}

.CodeMirror-linenumber {
    padding: 0 3px 0 5px;
    min-width: 20px;
    // width: fit-content;
    // margin-left: -16px;
    text-align: right;
    color: #999;
    white-space: nowrap;
}

.CodeMirror-guttermarker {
    color: black;
}

.CodeMirror-guttermarker-subtle {
    color: #999;
}

/* CURSOR */

.CodeMirror-cursor {
    border-left: 1px solid black;
    border-right: none;
    width: 0;
}

/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
    border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
    width: auto;
    border: 0 !important;
    background: #7e7;
}

.cm-fat-cursor div.CodeMirror-cursors {
    z-index: 1;
}

.cm-fat-cursor .CodeMirror-line::selection,
.cm-fat-cursor .CodeMirror-line>span::selection,
.cm-fat-cursor .CodeMirror-line>span>span::selection {
    background: transparent;
}

.cm-fat-cursor .CodeMirror-line::-moz-selection,
.cm-fat-cursor .CodeMirror-line>span::-moz-selection,
.cm-fat-cursor .CodeMirror-line>span>span::-moz-selection {
    background: transparent;
}

.cm-fat-cursor {
    caret-color: transparent;
}

@-moz-keyframes blink {
    0% {}

    50% {
        background-color: transparent;
    }

    100% {}
}

@-webkit-keyframes blink {
    0% {}

    50% {
        background-color: transparent;
    }

    100% {}
}

@keyframes blink {
    0% {}

    50% {
        background-color: transparent;
    }

    100% {}
}

/* Can style cursor different in overwrite (non-insert) mode */
.CodeMirror-overwrite .CodeMirror-cursor {}

.cm-tab {
    display: inline-block;
    text-decoration: inherit;
}

.CodeMirror-rulers {
    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
    bottom: 0;
    overflow: hidden;
}

.CodeMirror-ruler {
    border-left: 1px solid #ccc;
    top: 0;
    bottom: 0;
    position: absolute;
}

/* DEFAULT THEME */

.cm-s-default .cm-header {
    color: blue;
}

.cm-s-default .cm-quote {
    color: #090;
}

.cm-negative {
    color: #d44;
}

.cm-positive {
    color: #292;
}

.cm-header,
.cm-strong {
    font-weight: bold;
}

.cm-em {
    font-style: italic;
}

.cm-link {
    text-decoration: underline;
}

.cm-strikethrough {
    text-decoration: line-through;
}

.cm-s-default .cm-keyword {
    color: #708;
}

.cm-s-default .cm-atom {
    color: #219;
}

.cm-s-default .cm-number {
    color: #164;
}

.cm-s-default .cm-def {
    color: #00f;
}

.cm-s-default .cm-variable,
.cm-s-default .cm-punctuation,
.cm-s-default .cm-property,
.cm-s-default .cm-operator {}

.cm-s-default .cm-variable-2 {
    color: #05a;
}

.cm-s-default .cm-variable-3,
.cm-s-default .cm-type {
    color: #085;
}

.cm-s-default .cm-comment {
    color: #a50;
}

.cm-s-default .cm-string {
    color: #a11;
}

.cm-s-default .cm-string-2 {
    color: #f50;
}

.cm-s-default .cm-meta {
    color: #555;
}

.cm-s-default .cm-qualifier {
    color: #555;
}

.cm-s-default .cm-builtin {
    color: #30a;
}

.cm-s-default .cm-bracket {
    color: #997;
}

.cm-s-default .cm-tag {
    color: #170;
}

.cm-s-default .cm-attribute {
    color: #00c;
}

.cm-s-default .cm-hr {
    color: #999;
}

.cm-s-default .cm-link {
    color: #00c;
}

.cm-s-default .cm-error {
    color: #f00;
}

.cm-invalidchar {
    color: #f00;
}

.CodeMirror-composing {
    border-bottom: 2px solid;
}

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {
    color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
    color: #a22;
}

.CodeMirror-matchingtag {
    background: rgba(255, 150, 0, .3);
}

.CodeMirror-activeline-background {
    background: #e8f2ff;
}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
     the editor. You probably shouldn't touch them. */

.CodeMirror {
    position: relative;
    overflow: hidden;
    background: white;
}

.CodeMirror-scroll {
    overflow: scroll !important;
    /* Things will break if this is overridden */
    /* 50px is the magic margin used to hide the element's real scrollbars */
    /* See overflow: hidden in .CodeMirror */
    margin-bottom: -50px;
    margin-right: -50px;
    padding-bottom: 50px;
    height: 100%;
    outline: none;
    /* Prevent dragging from highlighting the element */
    position: relative;
    z-index: 0;
}

.CodeMirror-sizer {
    position: relative;
    border-right: 50px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
     before actual scrolling happens, thus preventing shaking and
     flickering artifacts. */
.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
    position: absolute;
    z-index: 6;
    display: none;
    outline: none;
}

.CodeMirror-vscrollbar {
    right: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}

.CodeMirror-hscrollbar {
    bottom: 0;
    left: 0;
    overflow-y: hidden;
    overflow-x: scroll;
}

.CodeMirror-scrollbar-filler {
    right: 0;
    bottom: 0;
}

.CodeMirror-gutter-filler {
    left: 0;
    bottom: 0;
}

.CodeMirror-gutters {
    position: absolute;
    left: 0;
    top: 0;
    min-height: 100%;
    z-index: 3;
}

.CodeMirror-gutter {
    white-space: normal;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: -50px;
}

.CodeMirror-gutter-wrapper {
    position: absolute;
    z-index: 4;
    background: none !important;
    border: none !important;
}

.CodeMirror-gutter-background {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 4;
}

.CodeMirror-gutter-elt {
    position: absolute;
    cursor: default;
    z-index: 4;
}

.CodeMirror-gutter-wrapper ::selection {
    background-color: transparent
}

.CodeMirror-gutter-wrapper ::-moz-selection {
    background-color: transparent
}

.CodeMirror-lines {
    cursor: text;
    min-height: 1px;
    /* prevents collapsing before first draw */
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
    /* Reset some styles that the rest of the page might have set */
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-width: 0;
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    white-space: pre;
    word-wrap: normal;
    line-height: inherit;
    color: inherit;
    z-index: 2;
    position: relative;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-variant-ligatures: contextual;
    font-variant-ligatures: contextual;
}

.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: normal;
}

.CodeMirror-linebackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.CodeMirror-linewidget {
    position: relative;
    z-index: 2;
    padding: 0.1px;
    /* Force widget margins to stay inside of the container */
}

.CodeMirror-widget {}

.CodeMirror-rtl pre {
    direction: rtl;
}

.CodeMirror-code {
    outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.CodeMirror-measure {
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.CodeMirror-cursor {
    position: absolute;
    pointer-events: none;
}

.CodeMirror-measure pre {
    position: static;
}

div.CodeMirror-cursors {
    visibility: hidden;
    position: relative;
    z-index: 3;
}

div.CodeMirror-dragcursors {
    visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
    visibility: visible;
}

.CodeMirror-selected {
    background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
    background: #d7d4f0;
}

.CodeMirror-crosshair {
    cursor: crosshair;
}

.CodeMirror-line::selection,
.CodeMirror-line>span::selection,
.CodeMirror-line>span>span::selection {
    background: #d7d4f0;
}

.CodeMirror-line::-moz-selection,
.CodeMirror-line>span::-moz-selection,
.CodeMirror-line>span>span::-moz-selection {
    background: #d7d4f0;
}

.cm-searching {
    background-color: #ffa;
    background-color: rgba(255, 255, 0, .4);
}

/* Used to force a border model for a node */
.cm-force-border {
    padding-right: .1px;
}

@media print {

    /* Hide the cursor when printing */
    .CodeMirror div.CodeMirror-cursors {
        visibility: hidden;
    }
}

/* See issue #2901 */
.cm-tab-wrap-hack:after {
    content: '';
}

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
    background: none;
}