@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'PingFang SC', '微软雅黑', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn {
  border-radius: 2px;

  &:not(:disabled):focus-visible {
    outline: none;
  }
}

.ant-modal-root .ant-modal-mask {
  background-color: @background-mask;
}

.ant-modal {

  .ant-modal-content {
    padding: 30px 40px;
    border-radius: 4px;

    .ant-modal-confirm-content {
      color: @foreground-dimmer;
    }

    .ant-modal-header {
      margin-bottom: 30px;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: @foreground-default;
    }

    .ant-modal-footer {
      margin-top: 40px;
      text-align: center;

      .ant-btn {
        min-width: 88px;
        height: 32px;
      }

      .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
        margin-inline-start: 40px;
      }
    }

    .ant-form-item {
      margin-bottom: 15px;

      .ant-form-item-label {
        margin-right: 4px;
        min-width: 70px;
      }

      .ant-form-item-label>label {
        color: @foreground-default;
      }

      .ant-select-selector,
      .ant-input,
      .ant-input-affix-wrapper {
        // color: @foreground-default;
        // border: 1px solid @outline-stronger;
        border-radius: 2px;
      }

      .ant-input-affix-wrapper {
        .ant-input {
          border: 0;
        }
      }

      .ant-input-show-count-suffix {
        color: @foreground-dimmest;
      }

      .ant-select-disabled .ant-select-selector {
        color: @foreground-dimmest;
      }

      .ant-input:focus,
      .ant-input-affix-wrapper:focus {
        box-shadow: none;
      }

      .ant-input-textarea-show-count {
        .ant-input-suffix {
          width: 45px;

          .ant-input-data-count {
            bottom: 5px;
            inset-inline-end: 5px;
          }
        }
      }
    }
  }
}

.ant-modal-confirm {
  .ant-modal-confirm-body>.anticon {
    margin-inline-end: 16px;
  }

  .ant-modal-confirm-title {
    color: @foreground-default;
  }

  .ant-modal-content {
    padding: 24px;
    // margin-inline-start: 38px;
    // margin-block-start: 12px;
  }

  .ant-modal-confirm-btns {
    margin-top: 23px;

    .ant-btn+.ant-btn {
      margin-inline-start: 10px;
    }
  }

}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item .ant-form-item-extra {
  color: @accent-negative-default;
}


.ant-tooltip .ant-tooltip-inner {
  padding: 0;
  color: #e5e5e5;
  font-size: 12px;
  border-radius: 2px;
  min-height: auto;
  box-shadow: none;
  overflow: hidden;
  text-align: center;

  .ant-upload-wrapper {
    display: block;
    font-size: 12px;
  }

  .subtitle-action {
    p {
      margin: 0;
      padding: 6px 8px;
      line-height: 12px;
      color: #e5e5e5;
      font-size: 12px;

      &:hover {
        cursor: pointer;
        background-color: #5C5D5D;
      }
    }
  }
}

.item-tooltip {
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 2px;
  padding: 4px;
  box-shadow: 1px 1px 2px 0px rgba(92,117,153,0.10); 

  .ant-tooltip-inner {
    background: #ffffff;
    color: #666666;
    line-height: 12px;
    text-align: left;
  }

  &-left {
    max-width: 460px;
    
    .ant-tooltip-inner {
      text-align: left !important;
      line-height: 14px;
    }
  }
}

.item-tooltip-title {
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 2px;
  padding: 4px;
  box-shadow: 1px 1px 2px 0px rgba(92,117,153,0.10); 

  .ant-tooltip-inner {
    background: #ffffff;
    color: #666666;
    line-height: 12px;
    text-align: left !important;
  }

  &-left {
    max-width: 460px;
    
    .ant-tooltip-inner {
      text-align: left !important;
      line-height: 14px;
    }
  }
}

.item-tooltip-mask {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;

  &:hover {
      background: #404047;
  }
}

.os-tooltip-border {
  border: 0.5px solid #e5e5e5;
  border-radius: 2px;
  box-shadow: 1px 1px 2px 0px rgba(92,117,153,0.10); 

  .ant-tooltip-inner {
      color: #666666;
      min-width: 20px;
      min-height: 20px;
      line-height: 12px;
      padding: 4px;
      font-size: 12px;
      font-weight: 400;
  }
}

@media screen and (max-width: 1440px) {
  body {}
}

.custom-tooltip {
  max-width: 400px;

  .ant-tooltip-content {
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 2px;
    box-shadow: 1px 1px 2px 0px rgba(92, 117, 153, 0.10);

    .ant-tooltip-inner {
      min-height: auto;
      padding: 4px;
      font-size: 12px;
      color: #999999 !important;
    }
  }
}