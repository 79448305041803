@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.env-startup {
  position: relative;

  .cpuAndMemory {
    margin-right: 10px;
    height: 32px;
    display: flex;
    align-items: center;
    color: @env-ccc;
    border: 1px solid @accent-primary-default;
    border-radius: 2px;

    >span {
      padding: 0 8px;
      display: flex;
      align-items: center;
      line-height: 32px;
      font-size: 14px;
      color: @accent-primary-default;
      white-space: nowrap;

      &:nth-child(1) {
        background-color: @accent-primary-default  !important;
        color: @header-name  !important;
        &:hover {
          cursor: auto !important;
        }
      }

      &:nth-child(3) {
        padding: 0 2px !important;
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(22, 119, 255, 0.10);
        color: #4592FF;
      }

      >span {
        margin-left: 4px;
      }
    }
  }

  .cpuAndMemory-hover {
    cursor: pointer;
    background: #252525;
    border-radius: 2px;
    color: @header-name  !important;
  }
}

.env-time {
  margin-right: 10px;
  padding-right: 10px;
  height: 16px;
  border-right: 0.5px solid @foreground-dimmer;
  display: flex;
  align-items: center;

  >span {
    margin-right: 5px;
    font-size: 16px;
    font-weight: 600;
    color: #30c213;
  }

  svg {
    fill: #999999 !important;

    &:hover {
      fill: @accent-primary-default  !important;
      cursor: pointer;
    }
  }
}

.env-loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  p {
    font-size: 20px;
  }
}

.ylzcc-modal-help {
  .ant-modal-content {
    padding: 30px 40px 40px 20px;
  }

  .ant-modal .ant-modal-close {
    color: #999999;

    &:hover {
      background-color: transparent;
      color: @accent-primary-default;
    }

  }

  .explain {
    div {
      margin-bottom: 15px;
      text-align: center;
      font-size: 16px;
    }

    p {
      margin: 0;
      color: #666666;
      line-height: 22px;
      display: flex;

      span {
        word-wrap: break-word;
      }
    }
  }
}