@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.publishques {
    width: 100%;
    height: 100%;

    &-main {
        height: calc(100% - 34px);
        background-color: rgb(250, 252, 255);
        overflow-y: auto;
        padding: 20px;

        &::-webkit-scrollbar {
            display: none;
        }

        &-labels-add {
            width: fit-content;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            text-align: LEFT;
            color: #1677ff;
            line-height: 14px;

            span {
                margin-left: 4px;
            }
        }

        &-labels-remove {
            cursor: pointer;
            --color: #999999;
            transform: translateY(-10px);
            margin-left: 10px;

            circle {
                stroke: var(--color);
            }

            rect {
                fill: var(--color);
            }

            &:hover {
                --color: #1677ff;
            }
        }

        &-labels-item {
            display: flex;
            align-items: center;

        }

        &-permission-tip {
            display: flex;
            align-items: start;

            p {
                margin: 0px;
                margin-left: 2px;
                font-size: 12px;
                font-weight: 400;
                text-align: LEFT;
                color: #999999;
                line-height: 18px;
            }
        }

        &-submit {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-btn {
            width: 134px;
            height: 28px;
            background: #1677ff;
            border-radius: 2px;
            display: flex;
            align-items: center;
            position: relative;

            &-dark {
                background-color: #cccccc;
                pointer-events: none;
            }

            .center {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-left {
                flex: 1;
                height: 100%;
                color: white;
                cursor: pointer;
                font-size: 14px;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.20);
                }
            }

            &-right {
                width: 22px;
                height: 100%;
                position: relative;
                color: white;

                &::before {
                    position: absolute;
                    content: '';
                    height: 14px;
                    top: 7px;
                    left: -0.5px;
                    width: 0.5px;
                    background-color: rgba(255, 255, 255, 0.50);
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.20);
                }
            }

            &-menus {
                position: absolute;
                top: 28px;
                left: 0;
                width: 100%;
                background: #ffffff;
                border: 0.5px solid #e5e5e5;
                border-radius: 2px;
                box-shadow: 2px 2px 4px 0px rgba(41, 45, 51, 0.10);
                overflow: hidden;
                transition: .2s;

                &-item {
                    height: 28px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    span {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &:hover {
                        background: #f0f2f5;
                    }
                }
            }
        }
    }

    .ant-form {

        .ant-select-selector,
        .ant-input-affix-wrapper {
            border-radius: 2px;
        }

        .ant-form-item-explain-error {
            line-height: 20px;
        }

        .ant-form-item {
            margin-bottom: 20px;
        }
    }
}