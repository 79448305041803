@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.ylzcc-addtools {
    .ant-modal-content {
        padding: 30px 50px;
        position: relative;

        .ant-form-item-no-colon {
            font-size: 14px !important;
            font-weight: 400 !important;

            &::before {
                display: none !important;
            }
        }

        .ant-form-item {
            margin-bottom: 20px;
        }

        .ant-form-item-explain-error {
            line-height: 20px;
        }

        .ant-input-affix-wrapper {
            border-radius: 2px !important;
            border-color: #e5e5e5;

            &-focused {
                border-color: #1677ff !important;
            }
        }

        .ant-input {
            border-radius: 2px !important;
            border-color: #e5e5e5 !important;

            &:focus {
                border-color: #1677ff !important;
            }
        }

        .ant-select-selector {
            border-radius: 2px !important;
            border-color: #e5e5e5;
        }

        // .ant-select-focused {
        //     border-color: red !important;
        // }
        // .ant-select-open {
        //     border-color: red !important;

        // }
    }

    &-title {
        font-size: 18px;
        font-weight: 600;
        text-align: CENTER;
        color: #333333;
        line-height: 18px;
        margin-bottom: 30px;
    }

    &-button {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            cursor: pointer;
        }

        gap: 40px;

        &-can {
            width: 88px;
            height: 32px;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            outline: none;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: #333333;
            line-height: 14px;

            &:hover {
                border: 1px solid #1677ff;
                color: #1677ff;
            }
        }

        &-en {
            outline: none;
            width: 88px;
            height: 32px;
            background: #1677ff;
            border-radius: 2px;
            border: none;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            line-height: 14px;

            &:hover {
                background: #4592ff;
            }
        }
    }

    .add-tools-list {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
    
        &-item {
            box-sizing: border-box;
            width: 44px;
            height: 44px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #e5e5e5;
            border-radius: 2px;
            background: #ffffff;
    
            img {
                width: 21px;
                height: 21px;
            }
        }
    
        &-light {
            border: 1px solid #1677ff;
        }
    }
    
}