@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.enter-workspace-loading {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0b0c0d;
    z-index: 9999;

    @time: 8s;

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .workspace {
            &-gifloading {
                width: 320px;
                height: 320px;
                position: relative;

                .svg1 {
                    width: 100%;
                    height: 100%;
                }

                .svg2 {
                    position: absolute;
                    inset: 49px;
                }

                .svg3 {
                    position: absolute;
                    left: 50%;
                    top: 132px;
                    transform: translateX(-50%);
                    animation: opacity 6s ease-in-out infinite;
                }
            }

            &-textloading {
                margin-top: 40px;
                height: 160px;
                overflow: hidden;

                > div {
                    transform: translateY(0);

                    &.move {
                        transition: 1s;
                        transform: translateY(-44px);
                    }
                }

                > div > div {
                    background-image: linear-gradient(90deg,#3589ff 0%, #28edff 100%);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    height: 20px;
                    text-shadow: 1px 1px 0px 0px rgba(0,29,102,0.80);
                    text-align: center;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-bottom: 24px;
                    opacity: 0.7;
                    transition: 1s;
                    
                    &.active {
                        font-size: 20px;
                        font-weight: 600;
                        opacity: 1;
                    }
                }
            }
        }
    }

    // 逆时针
    .workspace-anticlockwise {
        animation: rotate1 @time linear infinite;
    }

    // 顺时针
    .workspace-clockwise {
        animation: rotate2 @time linear infinite;
    }


    @keyframes rotate1 {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(-360deg);
        }
    }
    @keyframes rotate2 {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes opacity {
        0%,
        100% {
            opacity: 1;
        }

        50% {
            opacity: 0.4;
        }
    }
}