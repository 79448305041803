@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.loading {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.6);

  p {
    margin-top: 220px;
    font-size: 20px;
font-weight: 500;
color: #ffffff;
  }
  .btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88px;
height: 32px;
border: 1px solid #d9d9d9;
border-radius: 2px;
font-size: 14px;
color: #ffffff;
&:hover{
  cursor: pointer;
  background: rgba(255,255,255,0.10);
border: 1px solid #ffffff;
}
  }

  &-line {
    position: absolute;
    left: calc(50% - 70px);
    top: calc(50% - 90px);
    width: 140px;
    height: 140px;
    z-index: 1;

    div {
      position: absolute;
      width: 12px;
      height: 38px;
      border-radius: 6px;
      background: #1677ff;
      animation: load 1.04s ease infinite;
      -webkit-animation: load 1.04s ease infinite;

      &:nth-child(1) {
        left: 13px;
        top: 50%;
        margin-top: -15px;
        transform: rotate(270deg);
        animation-delay: 0.13s;
        -webkit-animation-delay: 0.13s;
        opacity: 1;
      }

      &:nth-child(2) {
        left: 25px;
        top: 15px;
        transform: rotate(315deg);
        animation-delay: 0.26s;
        -webkit-animation-delay: 0.26s;
        opacity: 0.9;
      }

      &:nth-child(3) {
        left: 50%;
        top: 0;
        margin-left: -6px;
        transform: rotate(0);
        animation-delay: 0.39s;
        -webkit-animation-delay: 0.39s;
        opacity: 0.8;
      }

      &:nth-child(4) {
        top: 15px;
        right: 25px;
        transform: rotate(45deg);
        animation-delay: 0.52s;
        -webkit-animation-delay: 0.52s;
        opacity: 0.7;
      }

      &:nth-child(5) {
        right: 13px;
        top: 50%;
        margin-top: -15px;
        transform: rotate(90deg);
        animation-delay: 0.65s;
        -webkit-animation-delay: 0.65s;
        opacity: 0.6;
      }

      &:nth-child(6) {
        right: 25px;
        bottom: 15px;
        transform: rotate(135deg);
        animation-delay: 0.78s;
        -webkit-animation-delay: 0.78s;
        opacity: 0.5;
      }

      &:nth-child(7) {
        bottom: 0;
        left: 50%;
        margin-left: -6px;
        transform: rotate(180deg);
        animation-delay: 0.91s;
        -webkit-animation-delay: 0.91s;
        opacity: 0.4;
      }

      &:nth-child(8) {
        bottom: 15px;
        left: 25px;
        transform: rotate(225deg);
        animation-delay: 1.04s;
        -webkit-animation-delay: 1.04s;
        opacity: 0.3;
      }
    }

    @keyframes load {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0.2;
      }
    }

    @-webkit-keyframes load {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0.2;
      }
    }
  }


}