@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色

.code-highlight {

    code.hljs {
        display: block;
        overflow-x: auto;
        padding: 1em;

        &::-webkit-scrollbar {
            height: 8px;
            width: 0;
        }
    
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #444444;
            border-radius: 10px;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #666666;
        }
    }

    .hljs {
        color: #abb2bf !important;
        background: #282c34 !important;
    }

    .hljs-comment,
    .hljs-quote {
        color: #5c6370 !important;
        font-style: italic !important;
    }

    .hljs-doctag,
    .hljs-formula,
    .hljs-keyword {
        color: #c678dd !important;
    }

    .hljs-deletion,
    .hljs-name,
    .hljs-section,
    .hljs-selector-tag,
    .hljs-subst {
        color: #e06c75 !important;
    }

    .hljs-literal {
        color: #56b6c2 !important;
    }

    .hljs-addition,
    .hljs-attribute,
    .hljs-meta .hljs-string,
    .hljs-regexp,
    .hljs-string {
        color: #98c379 !important;
    }

    .hljs-attr,
    .hljs-number,
    .hljs-selector-attr,
    .hljs-selector-class,
    .hljs-selector-pseudo,
    .hljs-template-variable,
    .hljs-type,
    .hljs-variable {
        color: #d19a66 !important;
    }

    .hljs-bullet,
    .hljs-link,
    .hljs-meta,
    .hljs-selector-id,
    .hljs-symbol,
    .hljs-title {
        color: #61aeee !important;
    }

    .hljs-built_in,
    .hljs-class .hljs-title,
    .hljs-title.class_ {
        color: #e6c07b !important;
    }

    .hljs-emphasis {
        font-style: italic
    }

    .hljs-strong {
        font-weight: 700
    }

    .hljs-link {
        text-decoration: underline
    }

}