@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.stage {
  width: 100%;
  padding: 15px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-left {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;

    &-index {
      width: 44px;
      height: 24px;
      background: linear-gradient(117deg, #ffce6c 52%, #fa9014 100%);
      border-radius: 8px;
      color: @background-default;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-name {
      overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
flex: 1;
      font-size: 16px;
      font-weight: 500;
      color: @foreground-default;
      line-height: 16px;
      margin-left: 15px;
    }
  }
  &-right{
    height: 24px;
    margin-right: 20px;
    cursor: pointer;
    &-none{
      width: 24px;
      height: 24px;
      border: 1px solid @foreground-dimmest;
      border-radius: 2px;
      &:hover{
        border: 1px solid @accent-primary-default;
      }
    }
    .noValue{
      width: 24px;
      height: 24px;
      border-radius: 2px;
      border: 1px solid #ccc;
      background: #e8e8e8;
    }
  }
}