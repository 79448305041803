@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.ques-lay {
    width: 100%;
    height: 100%;
}

.ques-co {
    width: 100%;
    height: calc(100% - 34px);
}

.ques {
    width: 100%;
    height: 100%;
    position: relative;

    &-publishtip {
        height: 56px;
        background: #ffffff;
        border-bottom: 0.5px solid #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 14px;

        p {
            margin-left: 3px;

            span {
                color: #1677ff;
                cursor: pointer;
                text-decoration-line: underline;
            }
        }
    }

    &-loading {
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 12px;
            margin-left: 4px;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
        }

        &-rotate {
            animation: rotate 2s linear infinite;
        }

        @keyframes rotate {
            0% {
                transform: rotate(0);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    &-nodata {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
            width: 120px;
            height: 120px;
        }

        .span {
            margin: 20px 0px;
            font-size: 12px;
            font-weight: 400;
            text-align: CENTER;
            color: #999999;
            line-height: 12px;
        }
    }

    &-footer-right {
        position: absolute;
        right: 20px;
        bottom: 20px;
        background: #e8f1ff;
        border-radius: 4px;
        box-shadow: 1px 1px 4px 0px rgba(41, 45, 51, 0.10);
        overflow: hidden;

        &-item {
            width: 36px;
            height: 36px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #999999;

            &:hover {
                background: #d0e4ff;
                color: #1677ff;
            }
        }
    }
}


.right-to-left-enter {
    transform: translateX(500px);
}
.right-to-left-active {
    transform: translateX(0px);
    transition: .3s ease;
}
.left-to-right-exit {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transform: translateX(0px);
}
.left-to-right-active {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateX(500px);
    transition: .3s ease;
}


.ques-css-enter {
    transform: translateX(-500px);
}
.ques-css-enter-active {
    transform: translateX(0px);
    transition: .3s ease;
}
.ques-css-exit {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateX(0px);
}
.ques-css-exit-active {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transform: translateX(-500px);
    transition: .3s ease;
}