@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.ylzcc-manage-modal {
    .ant-modal-content {
        padding: 30px 50px;
    }

    .manage {
        position: relative;
        &-title {
            font-size: 18px;
            font-weight: 600;
            text-align: CENTER;
            color: #333333;
            line-height: 18px;
            margin: 0 0 24px !important;
        }

        &-listtitle {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d9d9d9;
            margin-bottom: 16px;
            padding-bottom: 4px;
            font-size: 12px;
            font-weight: 600;
            color: #999999;
            line-height: 12px;
        }

        &-list {
            display: flex;
            flex-direction: column;
            gap: 20px;
            max-height: calc(100vh - 300px);
            overflow-y: auto;
            padding: 0 16px 0px 4px;
            
            &::-webkit-scrollbar {
                display: none;
            }
        }

        &-add {
            width: fit-content;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            text-align: LEFT;
            color: #1677ff;
            line-height: 16px;
            cursor: pointer;
            gap: 4px;
            margin-top: 20px;
            padding-left: 4px;
        }

        &-close {
            position: absolute;
            color: #999999;
            top: 6px;
            right: 6px;
            cursor: pointer;

            &:hover {
                color: #1677ff;
            }
        }

        &-footer {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;
        }

        &-nodata {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 10px;

            &-tip {
                margin-bottom: 30px;
                font-size: 14px;
                font-weight: 400;
                text-align: CENTER;
                color: #999999;
                line-height: 22px;
            }
        }
    }


}