@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.envform {
    padding: 30px 192px 30px 184px;

    .ant-form {
        .ant-form-item-no-colon {
            font-size: 14px !important;
            font-weight: 400 !important;
            font-size: 14px;
            color: #333333;
        }

        .ant-form-item-explain-error {
            line-height: 20px;
        }

        .ant-form-item {
            margin-bottom: 20px;
        }

        .ant-input,
        .ant-input-affix-wrapper {
            border-radius: 2px !important;
        }
    }

    &-image {
        position: relative;

        &-tip {
            position: absolute;
            height: 100%;
            left: calc(100% + 10px);
            top: 0;
            display: flex;
            align-items: flex-start;
            gap: 4px;
            color: #1677ff;
            padding-top: 6px;
            
            .span {
                min-width: 56px;
                width: fit-content;
                font-size: 14px;
                font-weight: 400;
                text-align: LEFT;
                line-height: 20px;
                cursor: pointer;
            }

            .nospan {
                color: rgba(22,119,255,0.50);
                pointer-events: none;
            }

            .img {
                width: 16px;
                height: 17px;
            }

        }
    }

    &-item {
        margin-bottom: 20px;
        padding-left: 10px;
        --left: 20px;

        &-title {
            margin-bottom: 16px;
            width: fit-content;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            text-align: LEFT;
            color: #333333;
            line-height: 14px;
            gap: 4px;
            cursor: pointer;

            svg {
                transition: .3s;
                fill: #1677ff;
            }
        }

        &-animation {
            display: grid;
            grid-template-rows: 0fr;
            transition: .3s;

            &-stamp {
                overflow: hidden;
            }
        }

        &-con {
            padding-left: var(--left);

            &-ports{
                display: flex;
                align-items: center;
                
                .svg {
                    cursor: pointer;
                    margin-left: 10px;
                    transform: translateY(-10px);
                    --color: #999999;
                    
                    circle {
                        stroke: var(--color);
                    }
                    rect {
                        fill: var(--color);
                    }

                    // &:hover {
                    //     --color: #1677ff;
                    // }

                    &[data-disabled=false] {
                        &:hover {
                            --color: #1677ff;
                        }
                    }

                    &[data-disabled=true] {
                        cursor: default;
                        pointer-events: none;
                    }
                }
            }

            &-border {
                padding: 20px 20px 0px;
                width: 100%;
                border: 1px solid #e5e5e5;
                border-radius: 2px;
                margin-bottom: 20px;

                &-line {
                    width: 100%;
                    display: flex;
                    align-items: center;
                }

                &-tools-list {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                
                    &-item {
                        box-sizing: border-box;
                        width: 44px;
                        height: 44px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #e5e5e5;
                        border-radius: 2px;
                        background: #ffffff;
                
                        img {
                            width: 21px;
                            height: 21px;
                        }

                        &[data-disabled=true] {
                            cursor: default !important;
                            pointer-events: none !important;
                        }
                    }
                
                    &-light {
                        border: 1px solid #1677ff;
                    }

                }
            }

            &-add {
                width: fit-content;
                cursor: pointer;
                font-size: 14px;
                font-weight: 400;
                color: #1677ff;
                line-height: 14px;
                gap: 4px;
                display: flex;
                align-items: center;
                
                svg {
                    fill: #1677ff;
                }
            }

            .adddark {
                cursor: default;
                pointer-events: none;
                color: #999999;
                svg {
                    fill: #999999;
                }
            }
        }

    }
}