@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.knowledge-drawer {
    .ant-drawer-content-wrapper {
        height: calc(100vh - 60px) !important;
        background: #ffffff;
        border-radius: 30px 30px 0px 0px;
        overflow: hidden;
    }

    .ant-drawer-body {
        padding: 40px 120px 20px;
        position: relative;
    }

    .close {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 20px;
        top: 20px;
        fill: #333333;
        cursor: pointer;

        &:hover {
            fill: #1677ff;
        }
    }

    .container {
        height: calc(100% - 72px);
        display: flex;

        &-left {
            width: 67%;
            margin-right: 30px;
            border-right: 1px solid #d9d9d9;

            &-table {
                height: calc(100% - 40px);
                padding-right: 30px;
                margin-top: 20px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    display: none;
                }

                .table-row {
                    height: 44px;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;

                    &:hover {
                        background: #f5f6fa;
                    }

                    &-knowledge {
                        margin-left: 30px;
                        flex: 1;
                        display: flex;
                        align-items: center;
                    }

                    &-triangle {
                        width: 10px;
                        height: 6px;
                        border-top: 6px solid #1677ff;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        transition: .3s;
                        margin-right: 10px;
                        cursor: pointer;
                    }

                    &-title {
                        width: 0;
                        flex: 1;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-size: 14px;
                        text-align: LEFT;
                        color: #333333;
                        line-height: 24px;
                    }

                    &-enter {
                        display: grid;
                        grid-template-rows: 0fr;
                    }
    
                    &-enter-active {
                        grid-template-rows: 1fr;
                        transition: .3s
                    }
    
                    &-exit {
                        display: grid;
                        grid-template-rows: 1fr;
                    }
    
                    &-exit-active {
                        grid-template-rows: 0fr;
                        transition: .3s
                    }
                }
            }
        }

        &-right {
            flex: 1;

            &-labels {
                height: calc(100% - 20px);
                padding-top: 30px;
                display: flex;
                flex-wrap: wrap;
                gap: 20px 10px;
                align-content: flex-start;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        &-title {
            position: relative;
            padding-left: 14px;
            font-size: 18px;
            font-weight: 600;
            text-align: LEFT;
            color: #333333;
            line-height: 20px;
            margin: 0;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 4px;
                height: 20px;
                background: #1677ff;
                border-radius: 2px;
            }
        }
    }
}