@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.ant-cascader-menu {
  height: auto !important;
}

.buttom1 {
  width: 80px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #333333;
}

// .modal-myself {

//   // .ant-modal-content {

//   //   .ant-form-item {
//   //       // margin-bottom: 20px;
//   //   }
//   // }
//   &-env {

//     &-enter {
//       padding-left: 20px;
//       display: flex;
//       align-items: center;
//       justify-content: left;
//       width: 100%;
//       margin-top: -15px;
//       margin-bottom: 3px;

//       span {
//         font-size: 12px;
//         font-weight: 400;
//         text-decoration: underline;
//         text-align: left;
//         color: #1677ff;
//         line-height: 12px;
//         cursor: pointer;
//       }

//       p {
//         margin-left: 10px;
//         font-size: 12px;
//         font-weight: 400;
//         text-align: LEFT;
//         color: #999999;
//         line-height: 12px;
//       }
//     }
//   }

//   &-tools {
//     &-list {
//       margin-top: -10px;
//       padding-left: 74px;

//       &-content {
//         width: 100%;
//         border: 1px solid #e5e5e5;
//         border-radius: 2px;
//         padding: 10px;
//         display: flex;
//         justify-content: left;
//         flex-wrap: wrap;
//         align-items: flex-start;
//         gap: 10px 8px;
//         max-height: 126px;
//         overflow-y: auto;

//         &-del {
//           position: absolute;
//           right: -7px;
//           top: -7px;
//           width: 14px;
//           height: 14px;
//           cursor: pointer;
//           display: flex;
//           justify-content: center;
//           align-items: center;

//           &:hover {
//             svg {
//               fill: #c5c5c5;
//             }
//           }

//           svg {
//             fill: #ddd;
//           }
//         }

//         span {
//           box-sizing: border-box;
//           background: #f5f6fa;
//           border: 0.5px solid #e5e5e5;
//           border-radius: 2px;
//           height: 28px;
//           padding: 4px 10px;
//           font-size: 14px;
//           line-height: 18px;
//           font-weight: 400;
//           color: #333333;
//           cursor: pointer;
//           min-width: 50px;
//           text-align: center;
//           position: relative;
//         }

//         .light {
//           background: rgba(22, 119, 255, 0.20);
//           border: 0.5px solid #1677ff;
//         }
//       }
//     }

//     &-add {
//       margin-top: 10px;
//       padding-left: 74px;
//       margin-bottom: 15px;

//       &-btn {
//         display: flex;
//         align-items: center;
//         font-size: 14px;
//         font-weight: 400;
//         text-align: LEFT;
//         color: #1677ff;
//         line-height: 16px;
//         cursor: pointer;
//         width: fit-content;

//         img {
//           width: 16px;
//           height: 16px;
//           margin-right: 4px;
//         }
//       }
//     }
//   }
// }