@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.detailques {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    &-con {
        width: 100%;
        height: calc(100% - 34px - 34px);

        .listcontainer-moreloading {
            background-color: #ffffff;
        }

        &-ques {
            margin-bottom: 16px;
            background: #ffffff;
            padding: 16px 20px;
            border-bottom: 0.5px solid #f0f0f0;

            p {
                margin: 0;
            }

            &-user {
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
                margin-bottom: 12px;

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }

                .title {
                    color: #666666;
                    margin-left: 10px;
                }

                .time {
                    flex: 1;
                    color: #999999;
                    margin-left: 10px;
                }

                .svg {
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                    color: #999999;
                    cursor: pointer;

                    &:hover {
                        background: rgba(22, 119, 255, 0.05);
                        color: #1677ff;
                    }
                }
            }

            &-title {
                // margin-bottom: 10px !important;
                font-size: 18px;
                font-weight: 600;
                text-align: LEFT;
                color: #333333;
                line-height: 26px;
            }

            &-content {
                width: calc(100% + 20px);
                position: relative;
                left: -10px;
                margin-bottom: 10px !important;
            }

            &-labels {
                width: 100%;
                height: 18px;
                overflow: hidden;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 4px;
                margin-bottom: 16px;

                &-type {
                    font-size: 12px;
                    font-weight: 400;
                    color: #faad14;
                    line-height: 12px;
                }

                &-label {
                    background: #e1e9f5;
                    border-radius: 10px;
                    font-size: 11px;
                    font-weight: 400;
                    text-align: LEFT;
                    color: #666666;
                    line-height: 18px;
                    padding: 0px 6px;
                }
            }

            &-from {
                display: flex;
                align-items: center;
                gap: 30px;
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                color: #999999;
                line-height: 12px;

                &-title {
                    display: flex;
                    align-items: end;
                    span:nth-child(2) {
                        color: #1677ff;
                        cursor: pointer;
                        text-decoration: underline;
                        max-width: 90px;
                        line-height: 16px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    span:hover:nth-child(2) {
                        color: #4592ff;
                    }
                }
            }
        }

        &-main {

            &-num {
                height: 34px;
                background: #ffffff;
                padding: 6px 20px 0px;
                position: relative;

                &::before {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 20px;
                    height: 0.5px;
                    width: calc(100% - 40px);
                    background-color: #d9d9d9;
                }
                
                span:nth-child(1) {
                    font-size: 14px;
                    font-weight: 600;
                    color: #333333;
                    line-height: 14px;
                }

                span:nth-child(2) {
                    font-size: 12px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 12px;
                    margin-left: 10px;
                }
            }

            &-list {
                background: #ffffff;
                
                &-nodata {
                    height: 100%;
                    min-height: 244px;
                    padding: 26px 0px 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    
                    img {
                        width: 120px;
                        height: 120px;
                    }

                    span {
                        margin-top: 20px;
                        font-size: 12px;
                        font-weight: 400;
                        text-align: CENTER;
                        color: #999999;
                        line-height: 12px;
                    }
                }
            }
        }
    }

    &-recover {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        --height: 34px;
        // height: 34px;
        height: var(--height);
        z-index: 199;
        transition: .3s;
        
        &-btn {
            width: 100%;
            border-top: 0.5px solid #f0f0f0;
            height: var(--height);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fafcff;
            overflow: hidden;

            span {
                font-size: 14px;
                cursor: pointer;
                font-weight: 400;
                text-decoration: underline;
                text-align: LEFT;
                color: #1677ff;
                line-height: 14px;
            }
        }

        &-submit {
            width: 100%;
            height: var(--height);
            border-top: 16px solid #fafcff;
            background: #ffffff;
            display: flex;
            align-items: start;
            padding: 20px;

            &-left {
                width: 28px;

                img {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                }
            }

            &-right {
                flex: 1;
                width: 0;
                margin-left: 10px;

                &-title {
                    font-size: 14px;
                    font-weight: 600;
                    text-align: LEFT;
                    color: #333333;
                    line-height: 14px;
                }

                &-editor {
                    width: 100%;
                    margin-top: 10px;
                    overflow: hidden;
                }

                &-btn {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: right;
                    gap: 10px;
                }
            }
        }
    }
}