@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.quesitem {
    padding: 20px;
    padding-bottom: 0px;
    display: flex;
    align-items: start;
    cursor: pointer;

    &:hover {
        background: #f5f6fa;
    }

    &-left {
        margin-right: 20px;
        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }

    &-right {
        flex: 1;
        width: 0;
        position: relative;
        padding-bottom: 20px;
        border-bottom: 0.5px solid #d9d9d9;

        p {
            margin: 0;
        }

        &-recover {
            position: absolute;
            right: 0;
            top: 0;
            color: #999999;
            width: fit-content;
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
                margin-left: 4px;
                color: #666666;
                font-size: 14px;
            }

            &:hover {
                color: #1677ff;

                span {
                    color: #1677ff;
                }
            }
        }

        &-one {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 12px;
            margin-bottom: 12px !important;

            span:nth-child(2) {
                margin-left: 10px;
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                line-height: 12px;
            }
            
        }

        &-two {
            padding-bottom: 12px;
            font-size: 12px;
            font-weight: 400;
            color: #faad14;
            line-height: 12px;

            span:nth-child(2) {
                margin-left: 10px;
            }
        }

        &-title {
            font-size: 16px;
            font-weight: 600;
            text-align: left !important;
            color: #333333;
            line-height: 22px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 12px !important;
            width: fit-content;
            max-width: 100%;

            &:hover {
                color: #1677ff;
            }
        }

        &-four {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            height: 18px;
            overflow: hidden;
            margin-bottom: 12px;
            gap: 4px;

            span {
                background: #e1e9f5;
                border-radius: 10px;
                font-size: 11px;
                font-weight: 400;
                text-align: LEFT;
                color: #666666;
                line-height: 18px;
                padding: 0px 6px;
            }
        }

        &-five {
            display: flex;
            align-items: center;
            gap: 30px;
            font-size: 12px;
            font-weight: 400;
            text-align: left;
            color: #999999;
            line-height: 12px;

            &-title {
                display: flex;
                align-items: end;
                span:nth-child(2) {
                    color: #1677ff;
                    cursor: pointer;
                    text-decoration: underline;
                    max-width: 90px;
                    white-space: nowrap;
                    line-height: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                span:hover:nth-child(2) {
                    color: #4592ff;
                }
            }
        }
    }
}