@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.scoredrawermarkwork {

    .ant-drawer-content-wrapper {
        height: calc(100vh - 60px) !important;
        background: #ffffff;
        border-radius: 30px 30px 0px 0px;
        overflow: hidden;
        padding: 30px 120px;
        overflow-y: auto;

        .ant-drawer-body {
            height: 100%;
            padding: 0;
        }
    }

    &-title {
        font-size: 18px;
        font-weight: 600;
        text-align: CENTER;
        color: #333333;
        line-height: 18px;
        margin-bottom: 30px;
    }

    &-tab {
        width: 100%;
        height: calc(100% - 48px - 32px - 30px);
        overflow: hidden;
        
        .ant-table-tbody > tr {
            .scoredrawermarkwork-score {
                height: 1px;
                
                &-con {
                    height: 100%;
                    width: 100%;
                    .input {
                        width: 60px;
                        height: 32px;
                        background: #ffffff;
                        border: 1px solid #d9d9d9;
                        border-radius: 2px;
                        text-align: center;
                    }
                }
            }
        }

        .ant-table-tbody tr {
            .order,
            .type {
                height: 1px;

                .centerstart {
                    height: 100%;
                    line-height: 22px !important;
                    width: 100%;
                }
            }
        }

        .scoredrawermarkwork-content {
            height: 1px;
            .content {
                height: 100%;
                max-width: 400px !important;
            }
        }

        // 设置表头
        .ant-table-header {
            border-radius: 0px !important;
        }
        .ant-table-thead tr th {
            background-color: #F5F6FA;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 16px;
            height: 40px;
            padding: 4px 16px;
            border-radius: 0px !important;
            border-start-start-radius: 0 !important;
            white-space: nowrap;

            &::before {
                display: none;
            }
        }

        .ant-table-tbody tr td {
            background-color: #ffffff !important;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
            border-bottom: 1px solid #d9d9d9;
        }

        .ant-table-thead .ant-table-cell-scrollbar {
            width: 0 !important;
        }

        .ant-table-body {
            overflow-y: auto !important;
        }

        // .ant-table-thead>tr>th,
        // .ant-table-thead>tr>td {
        //     background-color: white;
        //     font-size: 16px;
        //     font-weight: 400;
        //     text-align: CENTER;
        //     color: #333333;
        //     line-height: 16px;
        //     border-radius: 0 !important;

        //     &::before {
        //         display: none;
        //     }
        // }

        // .ant-table-cell {
        //     font-size: 14px;
        //     font-weight: 400;
        //     text-align: LEFT;
        //     color: #333333;
        //     line-height: 14px;
        // }

        // .ant-table-row {
        //     height: 60px;
        // }

        // .ant-table-thead {
        //     height: 60px;

        //     .ant-table-cell {
        //         padding: 12px;
        //     }

        // }

        // .ant-table-header {
        //     border-radius: 0px !important;
        // }


        // .ant-table-tbody>tr>td {
        //     border-bottom: 1px solid #ebebf0 !important;
        // }


        .ant-table-body {
            &::-webkit-scrollbar {
                width: 8px;
                height: 0px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #ededed;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #e0e0e0;
            }
        }

    }

    &-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }
}