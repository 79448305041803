@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.layout-opera {
    height: @header-height;
    background-color: @background-root;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @background-border;


    &-l {
        padding-left: 18px;
        display: flex;
        align-items: center;

        &-time {
            display: flex;
            align-items: center;
            font-size: 16px;
            padding: 0 10px;
            border-left: 1px solid #666666;
            line-height: 14px;
            margin-left: 10px;

            &-left {
                color: #FFFFFF;
            }

            &-rigth {
                color: #F5222D;
            }
        }

        svg {
            cursor: pointer;
        }

        &-type {
            font-size: 18px;
            font-weight: 600;
            color: @accent-primary-default;
            white-space: nowrap;
        }

        &-name-edit {
            display: flex;
            align-items: center;

            .ant-input-affix-wrapper {
                padding: 2px 11px;
                width: 267px;
                background-color: @background-25;
                border: 1px solid @background-border;
                border-radius: 2px;
                color: @workaspace-font-color;

                .ant-input {
                    background-color: @background-25;
                    color: @workaspace-font-color;

                    &::-webkit-input-placeholder {
                        color: @foreground-dimmest9;
                    }
                }

                .ant-input-show-count-suffix {
                    color: @foreground-dimmest9;
                }

                &:hover {
                    border-color: @background-border;
                }
            }

            svg {
                margin-left: 10px;
                color: @icon-default;
                vertical-align: middle;

                &:hover {
                    color: @accent-primary-default;
                }
            }
        }

        &-name {
            display: flex;
            align-items: center;

            &-text {
                cursor: pointer;
                display: inline-block;
                max-width: 216px;
                font-size: 18px;
                color: @header-name;
                font-weight: 600;
                user-select: none;
                white-space: nowrap;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            svg {
                margin-left: 4px;
                color: @icon-default;
                vertical-align: middle;

                &:hover {
                    color: @accent-primary-default;
                }
            }
        }

        &-save {
            margin-left: 20px;
            display: flex;
            align-items: center;

            span {
                margin-left: 4px;
                font-size: 14px;
                color: @foreground-dimmest;
            }
        }

        &-isUpdated {
            margin-left: 20px;
            display: flex;
            align-items: center;

            span {
                margin-left: 4px;
                color: @yellow;
                font-size: 14px;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    &-r {
        display: flex;
        align-items: center;

        .invaite-close {
            min-width: 64px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #1677FF;
            border: 1px solid #1677ff;
            border-radius: 2px;
            cursor: pointer;
            margin: 0 10px 0 0;

            &:hover {
                border: 1px solid #f5222d;
                color: #f5222d;
            }
        }

        .ant-btn {
            margin-right: 10px;
        }

        .ant-btn-primary {
            height: 32px;
            border-radius: 2px;
        }

        .ant-btn-primary.ant-btn-background-ghost {
            &:hover {
                background-color: @ant-btn-primary  !important;
            }
        }

        .exitBtn {
            color: #1677ff;
            background-color: transparent;
            border-color: #1677ff;
            box-shadow: none;

            &:hover {
                background: rgba(245, 34, 45, 0.10) !important;
                border-color: @accent-negative-default  !important;
                color: @accent-negative-default  !important;
            }
        }

        .inviteStudy {
            display: flex;
            align-items: center;
            height: 32px;
            border-radius: 2px;
            padding: 0 8px;
            justify-content: space-between;
            color: @env-ccc;
            margin-right: 10px;

            svg {

                fill: #9D9D9D !important;

            }

            &:hover {
                color: @accent-primary-default;
                cursor: pointer;
                background: @env-container;

                svg {

                    fill: @accent-primary-default  !important;

                }
            }
        }

        .inviteStudy-line {
            height: 15px;
            border-left: 0.5px solid #666666;
            margin-right: 2px;
        }

        .exeitBtn {
            display: flex;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 2px;
            padding: 0 8px;
            justify-content: center;
            color: @env-ccc;
            margin-right: 8px;

            &:hover {
                color: @accent-primary-default;
                cursor: pointer;
                background: @env-container;

            }
        }

        .exeit-line {
            height: 15px;
            border-left: 0.5px solid #666666;
            margin-right: 2px;
        }

        .ant-btn.ant-btn-loading {
            opacity: 1;
        }

        .ant-btn-link.ant-btn-dangerous {
            color: @accent-negative-default;
        }

        .ant-divider-vertical {
            margin: 0;
            border-inline-start: 1px solid @outline-default;
        }

        .ant-btn-default.ant-btn-dangerous {
            background-color: transparent;

            &:not(:disabled):hover {
                background-color: @accent-negative-default;
                color: #ffffff;
            }
        }
    }

    .loading {
        position: fixed;
        left: 0;
    }
}

.timeOver {

    .ant-modal-content {
        padding: 30px !important;
    }

    .ant-modal .ant-modal-close {
        display: none;
    }

    .ant-modal-footer {
        margin-top: 0 !important;
    }

    .timeOver-top {
        display: flex;
        align-items: center;

        .timeOver-text {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            margin-left: 16px;
        }
    }

    .timeOver-text1 {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin-top: 12px;
        margin-left: 38px;
    }

    .timeOver-bottom {
        width: 100%;
        display: flex;
        justify-content: end;
        margin-top: 23px;

        .timeOver-btnleft {
            padding: 0 16px;
            height: 32px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                border: 1px solid #1677FF;
                color: #1677FF;
            }
        }

        .timeOver-btn {
            margin-left: 15px;
            padding: 0 16px;
            height: 32px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            background: #1677ff;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background: #4096ff;
            }
        }
    }
}

.success1 {
    .ant-modal-content {
        margin-inline-start: 0px !important;
        margin-block-start: 0px !important;

        .ant-modal-confirm-content {
            margin-inline-start: 16px !important
        }

        .ant-modal-confirm-title {
            flex: none;
            margin-left: 16px;
        }
    }
}