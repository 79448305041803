@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.itab {
    height: 100%;
    max-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 9px 0 16px;

    // 图标颜色和高亮颜色
    @normal-color: #9D9D9D;
    @hover-color: white;
    position: relative;

    &-current {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: @accent-primary-default;
    }

    &-line {
        width: 1px;
        height: 16px;
        margin: 0 2px;
        background-color: #393e4d;
    }

    &-close {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            color: @normal-color;

            &:hover {
                color: @hover-color;
            }
        }
    }

    &-togater {
        height: 100%;
        max-width: 115px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-icon {
            padding-right: 4px;
            display: flex;
            align-items: center;

            img {
                width: 15px;
                height: 15px;
            }
        }

        &-title {
            flex: 1;
            height: 15px;
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            direction: rtl;
            
            span {
                line-height: 15px;
                unicode-bidi: bidi-override;
                direction: ltr;
            }
        }

        &-bot {
            padding-left: 4px;
            display: flex;
            align-items: center;

            svg {
                color: @normal-color;
            }
        }

        &:hover {
            color: #FFFFFF;

            svg {
                color: @hover-color;
            }
        }
    }
}

div,
span,
ul,
li {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.tabdiv-tip {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    box-shadow: 2px 2px 4px 0px rgba(92, 117, 153, 0.10);
    font-size: 12px;
    font-weight: 400;
    text-align: LEFT;
    color: #666666;
    line-height: 16px;
    padding: 4px 8px;
}