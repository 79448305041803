@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.multi-user {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  z-index: 101;

  &:hover {
      cursor: pointer;
  }

  &-icon {
      position: relative;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: @background-default;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  &-online {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  &-modal {
      position: absolute;
      top: -4px;
      right: 28px;
      z-index: 100;
      padding-right: 11px;

      &-contain {
          width: 120px;
          border: 0.5px solid @foreground-default;
          border-radius: 4px;
          box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
          background-color: @env-container;
          text-align: center;

          &-title {
              position: relative;
              padding: 9px 10px;
              font-size: 14px;
              color: @env-ccc;

              .line {
                  position: absolute;
                  width: 100px;
                  border-bottom: 0.5px dashed #333333;
                  bottom: 0;
              }
          }

          &-users {
              max-height: 500px;
              overflow-y: auto;
              overflow-x: hidden;

              &::-webkit-scrollbar {
                  width: 4px;
                  /* 滚动条宽度 */
              }

              /* 滚动条轨道 */
              &::-webkit-scrollbar-track {
                  background-color: transparent;
                  /* 滚动条轨道背景颜色 */
              }

              /* 滚动条滑块 */
              &::-webkit-scrollbar-thumb {
                  background-color: #444444;
                  /* 滚动条滑块颜色 */
                  border-radius: 2px;
                  /* 滑块圆角 */
              }

              /* 滚动条滑块悬停状态 */
              &::-webkit-scrollbar-thumb:hover {
                  background-color: #666666;
                  /* 滑块悬停时颜色 */
              }

              &-user {
                  padding: 8px 16px;
                  display: flex;
                  align-items: center;

                  div {
                      &:nth-child(1) {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          margin-right: 10px;
                          width: 16px;
                          height: 16px;
                          border-radius: 50%;
                      }

                      &:nth-child(2) {
                          font-size: 14px;
                          color: @env-ccc;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          max-width: 65px;
                      }
                  }
              }
          }
      }
  }
}