@background-root: #181818; // 工作台整体背景色
@background-container: #1e1e1e; // 整体容器背景色
@background-border: #333333; // 边框颜色
@workaspace-font-color: #ffffff; // 工作台字体主颜色

@background-default: #FFFFFF;
@background-active: #E2E3E5;
@background-hover: #F0F2F5;
@background-mask: rgba(0, 0, 0, 0.5);
@background-dark: #1e1e1e;
@background-light: #333333;
@background-light-stronger: #3d3d3d;
@background-line: rgba(255, 255, 255, 0.03);
@background-point: #5c5c5c;
@background-25 : #252525;

@foreground-default: #333333;
@foreground-dimmer: #666666;
@foreground-dimmest: #9D9D9D;
@foreground-dimmest9: #999999;
@foreground-light: #e5e5e5;
@outline-default: #D9D9D9;
@outline-stronger: #E5E5E5;
@accent-primary-default: #1677FF; // 高亮颜色
@accent-negative-default: #F5222D;
@accent-positive-default: #30C213;
@icon-default: #999999;
@icon-stronger: #666666;
@shadow-default: rgba(41, 45, 51, 0.15);
@yellow: #faad14;
@header-name: #FFFFFF;
@env-ccc: #CCCCCC;
@env-container: #282828;
@env-container-2f: #2f2f2f;
@ant-btn-primary: rgba(22, 119, 255, 0.10);



@header-height: 48px; // header高度
@footer-height: 22px; // 底下的高度
@sider-fold-width: 48px; // sider关闭宽度
@sider-item-title-height: 32px; // sider每个item的title的高度




// 容器组件相关的颜色
@container-nav: #252525; // 容器导航栏的颜色
@container-nav-itab-highlight: #1e1e1e; // 容器导itab高亮颜色
.step-arrow {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2px;

    &-item {
        flex: 1;
        height: 32px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        text-align: CENTER;
        line-height: 16px;
        cursor: pointer;
        
        &[data-status=finish],
        &[data-current=true] {
            background: #1677ff !important;
            color: #ffffff !important;
        }

        &[data-status=unfinish] {
            background: #f5f6fa;
            color: #666666;
        }
    
        &[data-type=start]::after {
            position: absolute;
            content: '';
            right: 0;
            top: 0;
            border-top: 16px solid #ffffff;
            border-bottom: 16px solid #ffffff;
            border-left: 16px solid transparent;
        }
    
        &[data-type=middle]{
            &::before {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                border-top: 16px solid transparent;
                border-bottom: 16px solid transparent;
                border-left: 16px solid #ffffff;
            }
            &::after {
                position: absolute;
                content: '';
                right: 0;
                top: 0;
                border-top: 16px solid #ffffff;
                border-bottom: 16px solid #ffffff;
                border-left: 16px solid transparent;
            }
        }
    
        &[data-type=end]::before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
            border-left: 16px solid #ffffff;
        }
    }
}